import React from "react";
import Link from "next/link"

import Store from '/src/store'

import { Get } from '/src/common/components/api'

import FooterContents from '/src/layouts/blocks/footerContents'
import ContentCheck from '/src/layouts/blocks/contentCheck'


import Meta from '/src/layouts/blocks/meta'
import ImageRenderer from '/src/common/components/imageRenderer'
import Image from 'next/image'

import styles from './index.module.sass'
import stylesLayout from '/src/layouts/layout.module.sass'

import UrlGenerator from '/src/common/helpers/urlGenerator'

import { withRouter } from 'next/router'

// 
import ShopsListItem from '/src/pages/shops/ShopsListItem'

import Footer from '/src/layouts/blocks/footer'

export async function getServerSideProps(router) {

  if (router.query.isSSR) {
    const promos = await Get('/promos')
    const contents = await Get('/contents', {'url': '/'})
    const seos = await Get('/seos', {'url': '/'})

    return {
      props: {
        promos: promos.list,
        contents: contents,
        seos: seos,
      },
    }

  }

  return {
    props: {}
  }
}

export class Component extends React.Component {



  constructor(props) {

    // const handleScroll = () => {
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //   const maxScroll = window.innerHeight;
    //   const newOpacity = Math.max(1 - scrollTop / maxScroll, 0);

    //   this.setState({
    //     opacity: newOpacity,
    //     scrollTop: scrollTop,
    //   });
    // };

    // if (typeof window != "undefined") {
    //   window.addEventListener('scroll', handleScroll);
    // }
    

    super(props);

    this.state = {
      populars: [],
      sets: [],

      promos: props.promos ?? [],
      contents: props.contents ?? {},
      seos: props.seos ?? {},

      // opaacity: 1,
      // scrollTop: 0,
    };

    if (props.promos == undefined) {
      Get('/promos', {'page_size': 3}).then(result => {
        this.setState({
          promos: result.list ?? []
        })
      }).catch(() => {

      })
    }

    if (props.contents == undefined) {
      Get('/contents', {'url': '/'}).then(result => {
        this.setState({
          contents: result ?? {}
        })
      }).catch(() => {

      })
    }

    if (props.seos == undefined) {
      Get('/seos', {'url': '/'}).then(result => {
        this.setState({
          seos: result ?? {}
        })
      }).catch(() => {

      })
    }





    Get('/shops/items/populars', {'page_size': 8}).then(result => {
      this.setState({
        populars: result.list ?? []
      })
    }).catch(() => {

    })


    Get('/shops/items/sets', {'page_size': 4}).then(result => {
      this.setState({
        sets: result.list ?? []
      })
    }).catch(() => {

    })

  }
  render() {

    return <div>

      {Meta(this.state.seos)}

      <div>

        <div className={styles.stories}>

          <Link href={`/promos`} className={styles.item}>
            <div className={styles.image}>
              <Image
                  src={"https://8c74e8fd-2177-4674-be73-6c3a75bc9999.selstorage.ru/demo/promos/1.jpg"}
                  width={600}
                  height={600}
                  alt={""}
                />
            </div>
          </Link>

          <Link href={`/promos`} className={styles.item}>
            <div className={styles.image}>
              <Image
                  src={"https://8c74e8fd-2177-4674-be73-6c3a75bc9999.selstorage.ru/demo/promos/2.jpg"}
                  width={600}
                  height={600}
                  alt={""}
                />
            </div>
          </Link>

          <Link href={`/promos`} className={styles.item}>
            <div className={styles.image}>
              <Image
                  src={"https://8c74e8fd-2177-4674-be73-6c3a75bc9999.selstorage.ru/demo/promos/3.jpg"}
                  width={600}
                  height={600}
                  alt={""}
                />
            </div>
          </Link>

          <Link href={`/promos`} className={styles.item}>
            <div className={styles.image}>
              <Image
                  src={"https://8c74e8fd-2177-4674-be73-6c3a75bc9999.selstorage.ru/demo/promos/4.jpg"}
                  width={600}
                  height={600}
                  alt={""}
                />
            </div>
          </Link>

          <Link href={`/promos`} className={styles.item}>
            <div className={styles.image}>
              <Image
                  src={"https://8c74e8fd-2177-4674-be73-6c3a75bc9999.selstorage.ru/demo/promos/1.jpg"}
                  width={600}
                  height={600}
                  alt={""}
                />
            </div>
          </Link>

          <Link href={`/promos`} className={styles.item}>
            <div className={styles.image}>
              <Image
                  src={"https://8c74e8fd-2177-4674-be73-6c3a75bc9999.selstorage.ru/demo/promos/2.jpg"}
                  width={600}
                  height={600}
                  alt={""}
                />
            </div>
          </Link>

        </div>





        <div className={stylesLayout.block}>
        
          {/*<ShopsCatsMenu />*/}


          {/*<div className={styles.banner}>

             

            <div className={styles.banner_wrapper}>

              <div className={styles.content}>
                <div className={`h0 ${styles.title}`}>
                  Интернет-магазин оптики
                </div>

                <div className={styles.btns}>
                  <Link href={`/check`} className={`btn _white ${styles.btn}`}>
                    Записаться на проверку
                  </Link>
                  <Link href={`/departments`} className={`btn _empty _white ${styles.btn}`}>
                    Адреса салонов
                  </Link>
                </div>

              </div>
            </div>


          </div>*/}

          
          {/*<div className={styles.cats}>

            <div className={`${styles.item} ${styles._big}`}>
              <div className={styles.wrapper}>
                111
              </div>
            </div>

            <div className={`${styles.item} ${styles.subitems}`}>

              <div className={styles.subitem}>
                <div className={styles.wrapper}>
                  222
                </div>
              </div>

              <div className={styles.subitem}>
                <div className={styles.wrapper}>
                  222
                </div>
              </div>

            </div>

            <div className={`${styles.item} ${styles._big}`}>
              <div className={styles.wrapper}>
                333
              </div>
            </div>

          </div>*/}



          {/*<div className={styles.advantages}>

            <div className={styles.item}>
              <div className={styles.image}>
                <img src="/template/demo/advantage-1.svg" alt="" />
              </div>
              <div className={styles.text}>
                Больше 50 салонов
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.image}>
                <img src="/template/demo/advantage-1.svg" alt="" />
              </div>
              <div className={styles.text}>
                50 000+ товаров
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.image}>
                <img src="/template/demo/advantage-1.svg" alt="" />
              </div>
              <div className={styles.text}>
                Доставка от 30 минут
              </div>
            </div>

          </div>*/}



          







          


          


        


          {/*<div className={styles.bottom}>
            <div className={styles.ddd}>
              <div className={styles.asd}>
                <span className={styles.value}>100</span>
                <span className={styles.title}>салонов</span>
              </div>
              <div className={styles.asd}>
                <span className={styles.value}>9 899</span>
                <span className={styles.title}>товаров</span>
              </div>
              <div className={styles.asd}>
                <span className={styles.value}>15</span>
                <span className={styles.title}>акций</span>
              </div>
              <div className={styles.asd}>
                <span className={styles.value}>17</span>
                <span className={styles.title}>услуг</span>
              </div>
              <div className={styles.asd}>
                <span className={styles.value}>7 (999) 999-88-77</span>
                <span className={styles.title}>онлайн-магазин</span>
              </div>
            </div>
          </div>*/}




          <div className={styles.promos}>

            <div className={styles.item}>
              <div className={`h1 ${styles.title}`}>
                Акции и скидки
              </div>
              <div className={styles.entry}>
                Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
              </div>
            </div>

            {this.state.promos.map((promo, key) => {

              return <Link href={`/promos/${promo.alias}`} className={styles.item} key={key}>
                <div className={styles.image}>
                  {promo.images && promo.images[0] && <Image
                      src={promo.images[0].image}
                      width={promo.images[0].width}
                      height={promo.images[0].height}
                      alt={promo.title}
                    />
                  }
                </div>
              </Link>
            })}

          </div>






          


          {this.state.populars.length > 0 && <div className={`${styles.sub}`}>

            <div className={stylesLayout.title_block}>
              <div className={`h1 ${stylesLayout.title}`}>
                Популярные товары
              </div>

              <div className={stylesLayout.entry}>
                Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
              </div>
            </div>


            <div className="shops_list">
              {this.state.populars.map((shopsItem, key) => {
                return <ShopsListItem key={key} shopsItem={shopsItem}/>
              })}
            </div>

          </div>}





          

          








          

          {/*<div className={styles.sub}>

            <div className={styles.info_header}>

              <div className="row">
                
                <div className="col-md-6">

                  <div className={styles.content}>

                    <div className={`h1 ${styles.title}`}>
                      Очки с доставкой по Санкт-Петербургу
                    </div>

                  </div>

                </div>
                
                <div className="col-md-6">
                  <div className={styles.content}>
                    <div className={styles.entry}>
                      Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
                    </div>
                  </div>
                </div>

              </div>

            </div>







            <div className={styles.advantages}>

              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.image}>
                    <img src="/template/demo/advantage-1.svg" alt="" />
                  </div>
                  <div className={styles.text}>
                    Работаем в 83-х регионах России
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.image}>
                    <img src="/template/demo/advantage-1.svg" alt="" />
                  </div>
                  <div className={styles.text}>
                    50 000+ товаров для здоровья и красоты
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.image}>
                    <img src="/template/demo/advantage-1.svg" alt="" />
                  </div>
                  <div className={styles.text}>
                    Привезём в аптеку или доставим домой от 30 минут
                  </div>
                </div>
              </div>
            </div>



          </div>*/}




          <ContentCheck />


          



          <div className={styles.sets}>


            <div className={styles.item}>
              <div className={`h1 ${styles.title}`}>
                Подборки товаров
              </div>
              <div className={styles.entry}>
                Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
              </div>
            </div>


            {this.state.sets.map((set, key) => {


              return <Link href={UrlGenerator({set: [set.alias]})} className={styles.item} key={key}>
                <div className={styles.image}>
                  {set.images && set.images[0] && <Image
                      src={set.images[0].image}
                      width={set.images[0].width}
                      height={set.images[0].height}
                      alt={set.title}
                    />
                  }
                </div>
              </Link>

              return <div className={styles.item} key={key}>

                  <Link href={UrlGenerator({set: [set.alias]})} className={styles.inner}>
                    {set.images && set.images[0] && <Image
                        src={set.images[0].image}
                        width={set.images[0].width}
                        height={set.images[0].height}
                        alt={set.title}
                      />
                    }
                  </Link>

                  {/*<Link href={UrlGenerator({set: [set.alias]})} className={styles.text}>
                    {set.title}
                  </Link>*/}
                </div>
            })}

          </div>









            















          <div className={styles.brands}>

          
          
            <div className={styles.brand}>
              <img src="/template/brands/versace.svg" alt="" />
            </div>
          
            <div className={styles.brand}>
              <img src="/template/brands/prada.svg" alt="" />
            </div>

          
            {/*<div className={styles.brand}>
              <img src="/template/brands/acuvue.svg" alt="" />
            </div>*/}

            <div className={styles.brand}>
              <img src="/template/brands/carrera.svg" alt="" />
            </div>

            <div className={styles.brand}>
              <img src="/template/brands/gucci.svg" alt="" />
            </div>

            <div className={styles.brand}>
              <img src="/template/brands/polaroid.svg" alt="" />
            </div>
            
            <div className={styles.brand}>
              <img src="/template/brands/ray_ban.svg" alt="" />
            </div>
          </div>







          {FooterContents(this.state.contents)}

        </div>

      </div>

      
      {/*<Footer />*/}
      
      

    </div>
  }

}


function WithRouter({ router, promos, contents, seos }) {
  return <Component
    router={router}
    promos={promos}
    contents={contents}
    seos={seos} />
}

export default withRouter(WithRouter)
