import React from "react";

import Header from './blocks/header'

import Cookie from './blocks/cookie'
import Footer from './blocks/footer'

import setHtml from '/src/store/actions/stylesAction'

import Store from '/src/store'

import { setSettings } from '/src/store/actions/settingsAction'
import { initAuth } from '/src/store/actions/usersAction'
import { setShopsCats } from '/src/store/actions/shopsCatsAction'

import styles from './layout.module.sass'


import HeaderSearch from '/src/layouts/blocks/header/search'
import HeaderMenu from '/src/layouts/blocks/header/menu'
import HeaderCart from '/src/layouts/blocks/header/cart'
import HeaderProfile from '/src/layouts/blocks/header/profile'

import Modal from '/src/common/components/modal'

export default class MainLayout extends React.Component {

  updateStateFromStore = () => {

    const currentState = this.getCurrentStateFromStore();

    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }

  getCurrentStateFromStore() {
    return {
      settings: Store.getState().settings.list
    }
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);

    document.onkeydown = function(e) {
      e = e || window.event;

      if (e.keyCode == 27) {

        if (Store.getState().styles.html == 'hdr-menu') {
          setHtml('')
        } else {
          setHtml('hdr-menu')
        }


        
      }
    };
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  componentDidUpdate() {

    this.state.settings.forEach(setting => {

      if (setting.value.length == 0) {
        return
      }

      let prefix = setting.alias.split('_')[0]

      if (prefix == 'color' || prefix == 'size') {
        let settingAlias = setting.alias.toLowerCase().replace(/([-_][a-z])/g, group =>
          group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
        )

        if (typeof document != "undefined") {
          document.querySelector(":root").style.setProperty('--'+settingAlias, setting.value)  
        }

      }

    })
  }


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isInited: false,
      isLoaded: false,

      settings: [],

      color: "#000"
    };

    Promise.all([
      setSettings(),
      initAuth(),
      setShopsCats(),
    ]).then(async () => {

      this.setState({
        isInited: true,
        isLoaded: true,
      })

      setHtml('')
    }).catch(() => {
    })

  }

  render() {
    // let color = '#000'
    // if (typeof document != "undefined") {
    //   color = getComputedStyle(document.body).getPropertyValue('--colorMain')

    //   if (this.state.color != color) {
    //     this.setState({
    //       color: color
    //     })
    //   }

      
      
    // }

    // function asd (e, that) {
    //   const color = e.target.value
    //   document.documentElement.style.setProperty('--colorMain', color)

    //   if (that.state.color != color) {
    //     that.setState({
    //       color: color
    //     })
    //   }
    // }

    return <div>

      <Modal />

      <HeaderSearch />
      <HeaderMenu />
      <HeaderCart />
      <HeaderProfile />

      <Header />
      {/*<Cookie />*/}
      
      {this.props.children}
      
      <Footer />

      {/*<div className={styles.color_block}>
        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="#fff" strokeLinecap="round" strokeWidth="1" d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"/>
        </svg>

        <input type="color" onChange={(e) => {asd(e, this)}} value={this.state.color} />
      </div>*/}
      
    </div>
  
  }
}

