import React from "react";
import Link from "next/link"

import setHtml from '/src/store/actions/stylesAction'

import Store from '/src/store'

import { Put } from '/src/common/components/api'

import InputMask from 'react-input-mask';

import styles from './headerProfile.module.sass'

export default class HeaderProfile extends React.Component {

  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();
    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }


  getCurrentStateFromStore() {
    return {
      htmlClass: Store.getState().styles['html'],
    }
  }
  
  componentDidMount() {
    // this.searchInputRef.focus();
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }





  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      error: null,
      isLoading: false,

      // usersNameFirst: Store.getState()['users']['profile']['name_first'],
      // usersNameLast: Store.getState()['users']['profile']['name_last'],
      // usersPhone: Store.getState()['users']['profile']['phone'] ? Store.getState()['users']['profile']['phone']+'' : '',
      // usersEmail: Store.getState()['users']['profile']['username'],
      usersComment: '',

      formErrors: {},
      htmlClass: '',
    };

  }

  handleChange(event) {

    // console.log('handleChange')

    this.setState({
      [event.target.dataset.name]: event.target.value,
    });

  }


  render() {

    const formParamsTitles = {
      usersNameFirst: 'Имя',
      usersNameLast: 'Фамилия',
      usersEmail: 'Email',
      usersPhone: 'Телефон',
      usersComment: 'Комментарий',
    }


    return this.state.htmlClass == 'hdr-profile' && <div>

      <div className={styles.profile_background} onClick={() => setHtml('')}>
      </div>

      <div className={styles.profile_block}>
    

        <ul className={styles.profile_list}>

          <li className={styles.item}>
            <Link href="/user" onClick={() => setHtml('')}>
              <span className={styles.icon}>
                <svg fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"/>
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                </svg>

              </span>
              Настройки
            </Link>
          </li>


          <li className={styles.line}></li>


          <li className={`${styles.item} ${styles._cart}`}>
            <div onClick={() => setHtml('hdr-cart-active')}>
              <span className={styles.icon}>
                <svg fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z"/>
                </svg>

              </span>

              Корзина

              {/*<div className={styles.quant}>{this.state.shopsCart.quant}</div>*/}
            </div>
          </li>



          <li className={styles.item}>
            <Link href="/orders" onClick={() => setHtml('')}>
              <span className={styles.icon}>
                <svg fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c2.819-.831 4.715-1.076 8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023"/>
                </svg>

              </span>

              Заказы
            </Link>
          </li>


          <li className={styles.item}>
            <Link href="/favorite" onClick={() => setHtml('')}>
              <span className={styles.icon}>
                <svg fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"/>
                </svg>

              </span>
              Избранное
            </Link>
          </li>



          




          <li className={styles.item}>
            <Link href="/orders" onClick={() => setHtml('')}>
              <span className={styles.icon}>
                <svg fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"/>
                </svg>
              </span>

              Рассылки
            </Link>
          </li>



          <li className={styles.line}></li>



          <li className={styles.item}>
            <Link href="#" onClick={() => {logout(); setHtml('')}}>
              <span className={styles.icon}>
                <svg fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H8m12 0-4 4m4-4-4-4M9 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h2"/>
                </svg>



              </span>
              Выйти
            </Link>
          </li>

        </ul>

      </div>

    </div>















    return <div className={styles.hdr_profile_block}>

	    <div className={styles.delete} onClick={() => setHtml('hdr-profile-active')}></div>

      <div className={styles.scroll}>

        <div className="hdr-block-wrapper">

          <div className="h3">
              Аккаунт
          </div>



            

          <ul className={styles.hdr_profile_list}>

            <li className={styles.item}>
              <Link href="/orders" onClick={() => setHtml('')}>
                  Заказы
              </Link>
            </li>

            <li className={styles.item}>
              <Link href="/orders" onClick={() => setHtml('')}>
                  Сертификаты
              </Link>
            </li>

            <li className={styles.item}>
              <Link href="/user" onClick={() => setHtml('')}>
                  Настройки
              </Link>
            </li>

            <li className={styles.item}>
              <Link href="" onClick={() => setHtml('')}>
                  Выйти
              </Link>
            </li>

          </ul>

          

{/*

          <div className={`form-group ${this.state.formErrors['usersNameFirst'] ? 'has-error' : ''}`}>
            <input value={this.state.usersNameFirst} type="text" id="shopsorders-usersNameFirst" className="form-control" onChange={this.handleChange} data-name="usersNameFirst" />
            <label htmlFor="shopsorders-usersNameFirst">
              {formParamsTitles['usersNameFirst']}
            </label>
            {this.state.formErrors['usersNameFirst'] && <div className="error">{this.state.formErrors['usersNameFirst']}</div>}
          </div>

          <div className={`form-group ${this.state.formErrors['usersNameLast'] ? 'has-error' : ''}`}>
            <input value={this.state.usersNameLast} type="text" id="shopsorders-usersNameLast" className="form-control" onChange={this.handleChange} data-name="usersNameLast" />
            <label htmlFor="shopsorders-usersNameLast">
              {formParamsTitles['usersNameLast']}
            </label>
            {this.state.formErrors['usersNameLast'] && <div className="error">{this.state.formErrors['usersNameLast']}</div>}
          </div>

          <div className={`form-group ${this.state.formErrors['usersPhone'] ? 'has-error' : ''}`}>

            <InputMask 
              mask='+7 (999) 999-99-99' 
              value={this.state.usersPhone} 
              id="shopsorders-usersPhone" 
              className="form-control"
              data-name="usersPhone"
              type="tel"

              onChange={this.handleChange}>
            </InputMask>

            <label htmlFor="shopsorders-usersPhone">
              {formParamsTitles['usersPhone']}
            </label>
            {this.state.formErrors['usersPhone'] && <div className="error">{this.state.formErrors['usersPhone']}</div>}
          </div>


          <div className={`form-group ${this.state.formErrors['usersEmail'] ? 'has-error' : ''}`}>
            <input value={this.state.usersEmail} type="text" id="shopsorders-usersEmail" className="form-control" onChange={this.handleChange} data-name="usersEmail" />
            <label htmlFor="shopsorders-usersEmail">
              {formParamsTitles['usersEmail']}
            </label>
            {this.state.formErrors['usersEmail'] && <div className="error">{this.state.formErrors['usersEmail']}</div>}
          </div>




          <button type="submit" className={`btn form-btn ${this.state.isLoading ? 'loading' : ''}`} onClick={()=> {

            if (this.state.isLoading) {
              return true;
            }


            const formParams = [
              'usersNameFirst',
              'usersNameLast',
              'usersPhone',
              'usersEmail',
              // 'usersComment',

            ]

            let formErrors = {}


            formParams.forEach(paramAlias => {

              if (typeof this.state[paramAlias] == 'undefined' || this.state[paramAlias] == '') {
                formErrors[paramAlias] = 'Укажите «'+formParamsTitles[paramAlias]+'»'
              }

            })

            if (Object.keys(formErrors).length != 0) {

              this.setState({
                formErrors: formErrors
              })

              return true
            }


            this.setState({
              isLoading: true
            })






            const userData = {
              name_first: this.state.usersNameFirst,
              name_last: this.state.usersNameLast,

              phone: this.state.usersPhone,
              email: this.state.usersEmail,

              token: Store.getState()['users'].token
            }

            putUser(userData).then(async (data) => {

              this.setState({
                isLoading: false,
              })

            }).catch(() => {
              
            })


          }}>Сохранить</button>
*/}



        </div>

      </div>

	</div>




  }
}




