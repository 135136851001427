import React from 'react';

import parse from "html-react-parser";

import styles from './footerConents.module.sass'

const FooterContents = (contents, params) => {

  return <div className="row">
    <div className="col-md-12">

      <div className={styles.seo}>

        <div className={styles.links}>
          <a href="/solntsezaschitnyie-ochki/boz/jenskie">Женские Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/uniseks">Унисекс Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/mujskie">Мужские Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/detskie">Детские Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/atsetat">Ацетат Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/metall">Металлические Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/kombinirovannyiy">Комбинированные Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/plastik">Пластиковые Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/stal">Стальные Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/titan">Титановые Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/karbon">Карбоновые Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/optil">Оптил Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/neylon">Нейлоновые Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/metall-gold-plated">Металл Gold Plated Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/metall-s-plastikovyim-zaushnikom">металл с пластиковым заушником Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/kombinirovannaya">Комбинированная Солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/metal">Метал солнцезащитные очки Boz</a>
          <a href="/solntsezaschitnyie-ochki/boz/polikarbonat">поликарбонат солнцезащитные очки Boz</a>
        </div>
      
        {/*<div className={styles.seo_entry}>
          Солнцезащитные очки Boz с доставкой по России
        </div>*/}

        <div className={styles.seo_content}>
          <p>Оптика "Niceyes" рада предложить вашему вниманию солнцезащитные очки Boz, которые являются надежной защитой для ваших глаз от вредного воздействия солнечных лучей. </p><p>Boz - это мировой лидер в производстве качественных солнцезащитных очков. Их продукция отличается высоким уровнем комфорта, стильным дизайном и передовыми технологиями. </p><p>В ассортименте оптики "Niceyes" вы найдете широкий выбор моделей Boz, подходящих для различных стилей и предпочтений. Очки Boz изготовлены из прочных и легких материалов, что обеспечивает долговечность и комфорт при ношении. </p><p>Доставка по России осуществляется удобными и надежными курьерскими службами. Вы можете заказать солнцезащитные очки Boz прямо сейчас на нашем сайте или позвонить нам по указанному номеру для консультации и оформления заказа. Мы гарантируем оперативную обработку и доставку вашего заказа в кратчайшие сроки. </p><p>Выбирайте качественные солнцезащитные очки Boz в оптике "Niceyes" и наслаждайтесь яркими солнечными днями, не опасаясь для здоровья своих глаз.</p>
        </div>

      </div>

    </div>

  </div>





  if (typeof(contents) == "undefined" || Object.keys(contents).length == 0) {
    return ''
  }

  if (params == undefined) {
    params = {}
  }

  let isShowTitle = params.isShowTitle ?? contents.title.length > 0
  let isShowEntry = params.isShowEntry ?? contents.entry.length > 0
  let isShowContent = params.isShowContent ?? contents.content.length > 0


  if (isShowTitle && isShowEntry && isShowContent) {
    return <div>
  
      <div className={styles.seo}>
      
        <h1>
          {contents.title}
        </h1>

        <div className="row">
          <div className="col-md-6">
            <div className={styles.seo_entry}>
              {parse(contents.entry)}
            </div>
          </div>
          <div className="col-md-10">
            <div className={styles.seo_content}>
              {parse(contents.content)}
            </div>
          </div>
        </div>
      </div>
  
    </div>
  }

  if (isShowTitle == false && isShowEntry && isShowContent) {
    return <div>
        <div className={styles.seo}>
          <div className="row">
            <div className="col-md-6">
              <div className="h1">
                {parse(contents.entry)}
              </div>
            </div>
          </div>
          <div className={styles.seo_content}>
            {parse(contents.content)}
          </div>
        </div>

    </div>
  }

  if (isShowTitle && isShowEntry == false && isShowContent) {
    return <div>

        <div className={styles.seo}>
          <div className="row">
            <div className="col-md-6">
              <h1 className="h1">
                {contents.title}
              </h1>
            </div>
            <div className={styles.seo_content}>
              {parse(contents.content)}
            </div>
          
          </div>
        </div>

    </div>
  }

  if (isShowTitle && isShowEntry && isShowContent == false) {
    return <div>

        <div className={styles.seo}>
          <h1>
            {contents.title}
          </h1>
          <div className={styles.seo_entry}>
            {parse(contents.entry)}
          </div>
        </div>

    </div>
  }

  if (isShowTitle == false && isShowEntry == false && isShowContent) {
    return <div>

        <div className={styles.seo}>
          <div className={styles.seo_content}>
            {parse(contents.content)}
          </div>
        </div>

    </div>
  }

  if (isShowTitle && isShowEntry == false && isShowContent == false) {
    return <div>

        <div className={styles.seo}>
          <h1>
            {contents.title}
          </h1>
        </div>
      
    </div>
  }

  return ''

}

export default FooterContents